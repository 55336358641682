import React from 'react';
import { IMD } from '../../../@types/ime';

import { Card, Spin } from 'antd';
import { Pie, PieConfig } from '@ant-design/charts';

interface IProps {
  data: IMD.IPieEntry[];
  width?: number;
  height: number;
  loading: boolean;
}

const InstitutionsTypePie = (props: IProps) => {
  const config: PieConfig = {
    data: props.data,
    width: props.width,
    height: props.height,
    angleField: 'count',
    colorField: 'label',
    radius: 0.7,
    legend: { color: { position: 'left' } },
    tooltip: {
      title: 'label',
      items: [
        { field: 'count', name: 'Count', valueFormatter: (d) => d.toLocaleString() },
        { field: 'percent', name: 'Percent', valueFormatter: '.2%' }
      ],
    },
    label: {
      position: 'outside',
      text: (item) => `${item.label}`,
      transform: [{ type: 'overlapDodgeY' }],
      fontSize: 10
    },
    style: { stroke: '#fff', inset: 1, radius: 4 },
    containerStyle: { display: 'flex', justifyContent: 'center' },
    scale: { color: { palette: 'purples' },  }
  };

  return (
    <Card
      style={{ width: '100%', flex: '1 1 0%' }}
      bordered={false}
      hoverable
    >
      <Spin tip="Loading Institutions Type Stats..." spinning={props.loading}>
        <h2>Institutions Type Stats</h2>
        <Pie {...config} />
      </Spin>
    </Card>
  );
};

export default InstitutionsTypePie;

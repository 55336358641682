import { IMD } from '../@types/ime';
import { throwHttpError } from '../errors/http-error';

class IMDService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_API_ENDPOINT || '';
  }

  async fetchMapData(collectionId: string, signal?: AbortSignal): Promise<IMD.IFetchMapDataResponse> {
    return fetch(`${this.API}/external/dashboard/finalDocument/statistics/country/${collectionId}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchAffiliationStatistics(collectionId: string, signal?: AbortSignal): Promise<IMD.IAffiliationStatistics> {
    return fetch(`${this.API}/external/dashboard/finalDocument/affiliationStatistics/${collectionId}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchSamplesTableData(collectionId: string, size: number, signal?: AbortSignal): Promise<IMD.IFetchSampleDataResponse> {
    return fetch(`${this.API}/external/dashboard/finalDocument/random/${collectionId}?size=${size}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchTypeData(collectionId: string, signal?: AbortSignal): Promise<IMD.IFetchTypeDataResponse> {
    return fetch(`${this.API}/external/dashboard/finalDocument/statistics/type/${collectionId}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchLevelData(collectionId: string, signal?: AbortSignal): Promise<IMD.IFetchLevelDataResponse> {
    return fetch(`${this.API}/external/dashboard/finalDocument/statistics/levels/${collectionId}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }
  
  async fetchSourcesData(collectionId: string, signal?: AbortSignal): Promise<IMD.IFetchSourcesDataResponse> {
    return fetch(`${this.API}/external/dashboard/finalDocument/statistics/histogramSourcesSize/${collectionId}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }
}

export default new IMDService();

import React from 'react';
import { IMD } from '../../../@types/ime';

import { Card, Flex, Spin, Statistic } from 'antd';
import CountUp from 'react-countup';
import { Buildings, Files, Percent } from '@phosphor-icons/react';

interface IProps {
  data: IMD.IAffiliationStatistics;
  loading: boolean;
}

const AffiliationStatistics = (props: IProps) => {
  const formatter = (value: number) => <CountUp end={value} separator="," decimals={Boolean(value % 1) ? 2 : 0} />;

  return (
    <Spin
      tip="Loading Institutions Type Stats..."
      spinning={props.loading}
    >
      <Card
        bodyStyle={{ padding: '14px 24px' }}
        bordered={false}
        hoverable
      >
        <Flex gap={20}>
          <Statistic title="Affiliations Count" value={props.data.affiliationsCount} formatter={formatter} prefix={<Buildings size={20} color="#6014b2" weight="light" />} />
          <Statistic title="Matches" value={props.data.finalDocumentsCount} precision={2} formatter={formatter} prefix={<Files size={20} color="#6014b2" weight="light" />} />
          <Statistic title="Matching Ratio" value={props.data.percentage * 100} precision={2} formatter={formatter} prefix={<Percent size={20} color="#6014b2" weight="light" />} />
        </Flex>
      </Card>
    </Spin>
  );
};

export default AffiliationStatistics;

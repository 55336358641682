import React from 'react';
import classes from './institutions.module.sass';

import { Card, Checkbox, Empty, Flex, Spin, Tooltip } from 'antd';
import { Map } from '../../components/core';
import { InstitutionsTypePie, AddressPie } from '../../components/reports';
import { RecordsTreeSearch } from '../../components/institutions';

import { useInstitutions } from '../../hooks';
import { Question } from '@phosphor-icons/react';

const InstitutionsPage = () => {
  const institutions = useInstitutions();

  return (
    <div className={classes.wrapper}>
      <Flex vertical gap={25}>
        <Flex>
          <Card
            bordered={false}
            hoverable
            style={{ minWidth: 400 }}
          >
            <Spin tip="Loading data sets..." spinning={institutions.loading.sources} style={{ width: '100%' }}>
              <span
                style={{
                  marginRight: 10,
                  display: 'inline-flex',
                  alignItems: 'center'
                }}
              >
                Data sets:
                <Tooltip overlay="The data source/s for the statistics"><Question size={12} color="#b6c1d2" /></Tooltip>
              </span>
              <Checkbox.Group
                disabled={Boolean(Object.values(institutions.loading).filter(Boolean).length)}
                options={institutions.sources || []}
                value={institutions.selectedSources}
                onChange={v => institutions.setSelectedSources(v)}
              />
            </Spin>
          </Card>
        </Flex>
        {
          institutions.selectedSources.length || institutions.loading.sources
            ? (
              <Flex align="flex-start" gap={25} vertical>
                <Map data={institutions.mapData} loading={institutions.loading.map} />
                <Flex className={classes.adaptiveStats} gap={25} wrap="wrap" style={{ width: '100%' }}>
                  <InstitutionsTypePie data={institutions.typeData} width={500} height={400} loading={institutions.loading.type} />
                  <AddressPie data={institutions.addressData} width={500} height={400} loading={institutions.loading.address} />
                </Flex>
                <Flex style={{ width: '100%' }}>
                  <RecordsTreeSearch
                    treeData={institutions.treeData}
                    searchData={institutions.treeSearchData}
                    loading={institutions.loading}
                    onOpenTree={institutions.loadTreeData}
                    onClearSearch={institutions.clearTreeData}
                    onSearchTreeData={institutions.searchTreeData}
                  />
                </Flex>
              </Flex>
            ) : <Empty description="Please select data source/s" />
        }
      </Flex>
    </div>
  );
};

export default InstitutionsPage;
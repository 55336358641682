import { TableProps } from 'antd';
import { IMD } from '../@types/ime';

const DATA_COLUMNS: TableProps<IMD.ISampleDataEntry>['columns'] = [
  {
    title: 'Input',
    dataIndex: 'affiliationOrgName',
    key: 'affiliationName'
  },
  {
    title: 'Matched institution Name',
    dataIndex: 'institutionName',
    key: 'institutionName'
  },
  {
    title: 'Matched Level',
    dataIndex: 'level',
    key: 'level'
  },
  {
    title: 'Country',
    dataIndex: ['address', 'country'],
    key: 'address.country',
  },
  {
    title: 'State',
    dataIndex: ['address', 'state'],
    key: 'address.state',
  },
  {
    title: 'City',
    dataIndex: ['address', 'city'],
    key: 'address.city',
  },
];

export {
  DATA_COLUMNS
};
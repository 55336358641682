import { Tag, Flex, List, Tooltip, Typography, message } from 'antd';
import { IMD } from '../../../@types/ime';
import { CHART_COLORS } from '../../../constants/charts';

const { Text } = Typography;

const RowDetails = (props: { record: IMD.ISampleDataEntry; }) => {
  const copyId = (id) => {
    navigator.clipboard.writeText(id);
    message.success({ content: "ID copied to clipboard!", duration: 1 });
  };

  return (
    <List
      size="small"
      bordered
      style={{ maxWidth: '65%' }}
      dataSource={props.record.institutionAncestors}
      renderItem={(item) => (
        <List.Item>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <span>{item.id}</span>
            <Text
              style={{ width: '360px' }}
              ellipsis={{ tooltip: item.name }}
            >
              {item.name}
            </Text>
            <div>
              {item.sources.map(
                (s, ind) => (
                  <Tooltip overlay={`${s.id} - Click to copy`}>
                    <Tag color={CHART_COLORS[ind]} onClick={() => copyId(s.id)}>{s.sourceName}</Tag>
                  </Tooltip>
                )
              )}
            </div>
          </Flex>
        </List.Item>
      )}
    />
  );
};

export default RowDetails;
import { IMD } from '../@types/ime';
import { throwHttpError } from '../errors/http-error';

class IMDService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_API_ENDPOINT || '';
  }

  async fetchMapData(sources: string[], signal?: AbortSignal): Promise<IMD.IFetchMapDataResponse> {
    return fetch(`${this.API}/external/dashboard/institution/statistics/country?sources=${sources.join(',')}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchTypeData(sources: string[], signal?: AbortSignal): Promise<IMD.IFetchTypeDataResponse> {
    return fetch(`${this.API}/external/dashboard/institution/statistics/type?sources=${sources.join(',')}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchAddressData(sources: string[], signal?: AbortSignal): Promise<IMD.IFetchAddressDataResponse> {
    return fetch(`${this.API}/external/dashboard/institution/statistics/address?sources=${sources.join(',')}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchSources(signal?: AbortSignal): Promise<IMD.IFetchSourcesResponse> {
    return fetch(`${this.API}/external/institution/sources`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchTreeData(sources: string[], query: string, signal?: AbortSignal): Promise<IMD.IFetchTreeResponse> {
    return fetch(`${this.API}/external/institution/subtree/${query}?sources=${sources.join(',')}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async searchInstitutionsTree(sources: string[], query: string, signal?: AbortSignal): Promise<IMD.ISearchTreeResponse> {
    return fetch(`${this.API}/external/institution?sources=${sources.join(',')}&search=${query}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }
}

export default new IMDService();

import { IMD } from '../../../@types/ime';

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";

import { useLayoutEffect, useMemo } from 'react';
import { Card, Spin } from 'antd';

interface IProps {
  data: IMD.IMapEntry[];
  loading: boolean;
}

const Map = (props: IProps) => {
  const [maxValue, minValue] = useMemo(() => [Math.max(...props.data.map(o => o.value)), Math.min(...props.data.map(o => o.value))], [props.data]);

  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv", {});

    const myTheme = am5.Theme.new(root);

    myTheme.rule("MapPolygon").setAll({ fill: am5.color(0xB4BEF0) });

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    let chart = root.container.children.push(
      am5map.MapChart.new(root,
        {
          maxZoomLevel: 60,
          centerMapOnZoomOut: false,
          zoomLevel: 1.2,
          animationEasing: am5.ease.out(am5.ease.exp),
          animationDuration: 350,
          zoomStep: 1.3
        }
      ));

    chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

    chart.series.push(am5map.MapPolygonSeries.new(root, { geoJSON: am5geodata_worldLow, exclude: ["AQ"] }));

    let bubbleSeries = chart.series.push(
      am5map.ClusteredPointSeries.new(root, {
        valueField: "value",
        scatterRadius: 5,
        polygonIdField: "id",
        minDistance: 12,
        stopClusterZoom: 0.7
      })
    );

    let circleTemplate = am5.Template.new({});

    bubbleSeries.bullets.push(function (root) {
      let container = am5.Container.new(root, {});

      container.children.push(
        am5.Circle.new(root, {
          fillOpacity: 0.8,
          fill: am5.color(0x344285),
          cursorOverStyle: "pointer",
          tooltipText: '{name}: [bold]{value}[/]'
        }, circleTemplate as any)
      );

      return am5.Bullet.new(root, {
        sprite: container,
        dynamic: true
      });
    });

    bubbleSeries.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: "{value.formatNumber('#.')}",
          fill: am5.color(0xedf2f9),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: "center",
          fontSize: 11
        }),
        dynamic: true
      });
    });

    bubbleSeries.set("heatRules", [
      {
        target: circleTemplate,
        dataField: "value",
        min: 11,
        max: 30,
        minValue,
        maxValue,
        key: "radius"
      }
    ]);

    bubbleSeries.data.setAll(props.data);

    return () => { root.dispose(); };
  }, [props.data]);

  return (
    <Card
      style={{ width: '100%' }}
      bodyStyle={{ padding: 0 }}
      bordered={false}
      hoverable
    >
      <Spin tip="Loading map data..." spinning={props.loading}>
        <div id="chartdiv" style={{ width: '100%', height: '500px' }}></div>
      </Spin>
    </Card>
  );
};

export default Map;
import React, { useEffect, useMemo, useState } from 'react';
import { IMD } from '../../../@types/ime';
import classes from './records-tree-search.module.sass';

import { TreeStructure } from '@phosphor-icons/react';
import { Button, Card, Input, Modal, Spin, Table, Tree } from 'antd';

const { Search } = Input;
const INITIAL_PAGINATION = { current: 1, pageSize: 10 };

interface IProps {
  treeData: IMD.ITreeNode[];
  searchData: IMD.ISearchTreeItem[];
  loading: { [key: string]: boolean; };
  onClearSearch: () => void;
  onOpenTree: (string) => void;
  onSearchTreeData: (query: string) => void;
}

const RecordsTreeSearch = (props: IProps) => {
  const [showTree, setShowTree] = useState(false);
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const defaultSelected = useMemo(() => [props.treeData?.[0]?.key], [props.treeData]);

  useEffect(() => {
    setPagination(INITIAL_PAGINATION);
  }, [props.searchData]);

  const handleShowTree = (id: string) => {
    props.onOpenTree(id);
    setShowTree(true);
  };

  return (
    <Card
      style={{ width: '100%', marginBottom: '40px' }}
      bordered={false}
      hoverable
    >
      <div className={classes.wrapper}>
        <h2>Search the dataset </h2>
        <Search
          placeholder="Enter search query"
          allowClear
          loading={props.loading.treeSearch}
          onSearch={v => v ? props.onSearchTreeData(v) : props.onClearSearch()}
          style={{ width: 400, marginBottom: 10 }}
        />
        <Table
          rowKey="id"
          className={classes.resultsTable}
          scroll={{ y: 600 }}
          locale={{
            emptyText: 'Search institutes to show data'
          }}
          columns={[
            {
              title: 'Results',
              dataIndex: 'name',
              key: 'id',
              render: (v) => (<div dangerouslySetInnerHTML={{ __html: v }} />)
            },
            {
              key: 'action',
              title: 'Open Institute Tree',
              width: 300,
              render: (v: IMD.ISearchTreeItem) =>
                v.numberOfChildren ? (
                  <Button type="dashed" shape="circle" title="Open Institute Tree" onClick={() => handleShowTree(v.id)}>
                    <TreeStructure size={22} color="var(--falcon-primary)" />
                  </Button>
                ) : null
            }
          ]}
          pagination={{ ...pagination, onChange: (current, pageSize) => setPagination({ current, pageSize }) }}
          dataSource={props.searchData}
        />
        {
          !props.loading.tree && (
            <Modal
              open={showTree}
              cancelButtonProps={{ style: { display: 'none' } }}
              okText="Close"
              closeIcon={false}
              onOk={() => setShowTree(false)}
              onCancel={() => setShowTree(false)}
              width={1000}
              style={{ top: 20 }}
              styles={{ body: { minHeight: '100px', maxHeight: '85vh', overflow: 'auto' } }}
            >
              <Spin tip="Loading Tree Data" spinning={props.loading.tree}>
                <Tree
                  style={{ minWidth: 700, width: '100%' }}
                  defaultExpandedKeys={defaultSelected}
                  showLine
                  showIcon
                  blockNode
                  expandAction="click"
                  treeData={props.treeData}
                />
              </Spin>
            </Modal>
          )
        }
      </div>
    </Card>
  );
};

export default RecordsTreeSearch;

import React, { useMemo } from 'react';
import classes from './header.module.sass';
import logo from '../../../assets/icons/logo_4.svg';

import { Button, Flex } from 'antd';
import { Header as AntDHeader } from 'antd/es/layout/layout';

import { useLocation } from 'react-router-dom';
import { List } from '@phosphor-icons/react';

const Header = () => {
  const location = useLocation();
  const currentPage = useMemo(() => location.pathname.split('/')[1] || '', [location]);

  const menu = [
    {
      key: 'institutions',
      label: 'Institutions',
      href: `/institutions`
    }
  ];

  return (
    <AntDHeader className={classes.wrapper}>
      <Button className={classes.menuButton} type="text" shape="circle">
        <List size={24} color="var(--falcon-gray-700)" />
      </Button>
      <img src={logo} height={30} />
      <a href="/institutions" className={classes.title}><h1>IM Dashboard</h1></a>
      <Flex
        className={classes.navBar}
      >
        {
          menu.map(item => (
            <Button
              type="link"
              {...item}
            >
              {item.label}
            </Button>
          ))
        }
      </Flex>
    </AntDHeader>
  );
};

export default Header;
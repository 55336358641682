import React from 'react';
import { IMD } from '../../../@types/ime';

import { Card, Spin } from 'antd';
import { ColumnConfig, Column } from '@ant-design/charts';

interface IProps {
  data: IMD.IColumnEntry[];
  title: string;
  width?: number;
  height: number;
  loading: boolean;
}

const ColumnChart = (props: IProps) => {
  const config: ColumnConfig = {
    data: props.data,
    width: props.width,
    height: props.height,
    xField: 'label',
    yField: 'count',
    tooltip: {
      title: 'label',
      items: [{ field: 'count', name: 'Count', valueFormatter: (d) => d.toLocaleString() }]
    },
    style: { radiusTopLeft: 7, radiusTopRight: 7, fill: '#565597' }
  };

  return (
    <Card
      style={{ width: '100%', flex: '1 1 0%' }}
      bordered={false}
      hoverable
    >
      <Spin tip={`Loading ${props.title.toLocaleLowerCase()}...`} spinning={props.loading}>
        <h2>{props.title}</h2>
        <Column {...config} />
      </Spin>
    </Card>
  );
};

export default ColumnChart;
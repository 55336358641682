import { ThemeConfig } from 'antd';

const primary = "#5872b9";

const theme: ThemeConfig = {
  token: {
    colorPrimary: primary,
    fontFamily: 'var(--falcon-font-sans-serif)'
  },
  components: {
    Layout: {
      headerBg: 'var(--falcon-body-bg)',
      bodyBg: 'var(--falcon-body-bg)',
      headerHeight: 70,
      headerPadding: '8px 16px'
    },
    Radio: {
      colorPrimary: "#666",
      buttonCheckedBg: "#ebedff"
    },
    Menu: {
      algorithm: false,
      itemBg: 'var(--falcon-body-bg)',
      itemSelectedBg: 'var(--falcon-body-bg)',
      itemPaddingInline: 10,
    },
  }
};

export default theme;
import { useRef, useEffect } from 'react';

/**
 *
 * An extension of useEffect hook that does not execute on the first render cycle.
 *
 * @param effect Imperative function that can return a cleanup function.
 * @param Optional If present, effect will only activate if the values in the list change.
 */
const useLazyEffect = (effect: React.EffectCallback, deps?: React.DependencyList | undefined) => {
  const firstRender = useRef(true);
  useEffect(() => firstRender.current ? undefined : effect ? effect() : undefined, deps);
  useEffect(() => { firstRender.current = false; }, []);
};

export default useLazyEffect;
import { IMD } from '../@types/ime';

import { App } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import imdResultsService from '../services/imd-results.service';
import useLazyEffect from './lazy-effect.hook';

interface IState {
  loading: { [key: string]: boolean; };
  mapData: IMD.IMapEntry[];
  typeData: IMD.IPieEntry[];
  levelData: IMD.IColumnEntry[];
  sourcesData: IMD.IColumnEntry[];
  affiliationStats: IMD.IAffiliationStatistics;
  sampleData: IMD.ISampleDataEntry[];
};

const emptyData = {
  mapData: [],
  sampleData: [],
  typeData: [],
  levelData: [],
  sourcesData: [],
  affiliationStats: { affiliationsCount: 0, finalDocumentsCount: 0, percentage: 0 }
};

const initialState: IState = {
  loading: { sources: true, type: true, level: true },
  ...emptyData
};

const useResults = () => {
  const [state, setState] = useState<IState>(initialState);
  let params = useParams();
  let [qsParams] = useSearchParams();
  const { message } = App.useApp();

  const sampleSize = Number(qsParams.get('size') || 5);
  const collectionId = params['collection'];

  const maC = useRef<AbortController>();
  const afC = useRef<AbortController>();
  const sdC = useRef<AbortController>();
  const tdC = useRef<AbortController>();
  const ldC = useRef<AbortController>();
  const sudC = useRef<AbortController>();

  const loadMapData = useCallback(() => {
    if (!state.loading.map) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, map: true } }));
    }

    if (maC.current) {
      maC.current.abort();
    }

    maC.current = new AbortController();

    imdResultsService.fetchMapData(collectionId, maC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, mapData: res.result, loading: { ...oldState.loading, map: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, map: false } }));
        message.error('Failed to load map data!');
      });
  }, [collectionId]);

  const loadAffiliationStats = useCallback(() => {
    if (!state.loading.affiliation) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, affiliation: true } }));
    }

    if (afC.current) {
      afC.current.abort();
    }

    afC.current = new AbortController();

    imdResultsService.fetchAffiliationStatistics(collectionId, afC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, affiliationStats: res, loading: { ...oldState.loading, affiliation: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, affiliation: false } }));
        message.error('Failed to load affiliation statistics data!');
      });
  }, [collectionId]);

  const loadSampleTableData = useCallback(() => {
    if (!state.loading.sample) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, sample: true } }));
    }

    if (sdC.current) {
      sdC.current.abort();
    }

    sdC.current = new AbortController();

    imdResultsService.fetchSamplesTableData(collectionId, sampleSize, sdC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, sampleData: res, loading: { ...oldState.loading, sample: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, sample: false } }));
        message.error('Failed to load Sample Data!');
      });
  }, [collectionId, sampleSize]);

  const loadTypeData = useCallback(() => {
    if (!state.loading.type) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, type: true } }));
    }

    if (tdC.current) {
      tdC.current.abort();
    }

    tdC.current = new AbortController();

    imdResultsService.fetchTypeData(collectionId, tdC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, typeData: res.result, loading: { ...oldState.loading, type: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, type: false } }));
        message.error('Failed to load type Data!');
      });
  }, [collectionId]);

  const loadLevelData = useCallback(() => {
    if (!state.loading.level) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, level: true } }));
    }

    if (ldC.current) {
      ldC.current.abort();
    }

    ldC.current = new AbortController();

    imdResultsService.fetchLevelData(collectionId, ldC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, levelData: res.result, loading: { ...oldState.loading, level: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, level: false } }));
        message.error('Failed to load level Data!');
      });
  }, [collectionId]);

  const loadSourcesData = useCallback(() => {
    if (!state.loading.level) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, sources: true } }));
    }

    if (sudC.current) {
      sudC.current.abort();
    }

    sudC.current = new AbortController();

    imdResultsService.fetchSourcesData(collectionId, sudC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, sourcesData: res.result, loading: { ...oldState.loading, sources: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, sources: false } }));
        message.error('Failed to load sources Data!');
      });
  }, [collectionId]);

  useEffect(() => {
    loadMapData();
    loadAffiliationStats();
    loadSampleTableData();
    loadTypeData();
    loadLevelData();
    loadSourcesData();
  }, []);

  useLazyEffect(() => {
    loadSampleTableData();
  }, [sampleSize]);

  return {
    loadSampleTableData,
    ...state
  };
};

export default useResults;
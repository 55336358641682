import { App } from 'antd';
import { IMD } from '../@types/ime';
import imdService from '../services/imd.service';

import { CheckboxOptionType } from 'antd/es/checkbox/Group';

import { useCallback, useEffect, useRef, useState } from 'react';
import useLazyEffect from './lazy-effect.hook';

interface IState {
  loading: { [key: string]: boolean; };
  sources: CheckboxOptionType[];
  mapData: IMD.IMapEntry[];
  typeData: IMD.IPieEntry[];
  treeData: IMD.ITreeNode[];
  addressData: IMD.IPieEntry[];
  treeSearchData: IMD.ISearchTreeItem[];
};

const emptyData = {
  mapData: [],
  typeData: [],
  addressData: [],
  treeData: [],
  treeSearchData: []
};

const initialState: IState = {
  loading: { sources: true },
  sources: [],
  ...emptyData
};

const useBrowse = () => {
  const [state, setState] = useState<IState>(initialState);
  const [selectedSources, setSelectedSources] = useState([]);
  const { message } = App.useApp();

  const maC = useRef<AbortController>();
  const tyC = useRef<AbortController>();
  const adC = useRef<AbortController>();
  const soC = useRef<AbortController>();
  const tdC = useRef<AbortController>();
  const tdsC = useRef<AbortController>();

  const loadMapData = useCallback(() => {
    if (!state.loading.map) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, map: true } }));
    }

    if (maC.current) {
      maC.current.abort();
    }

    maC.current = new AbortController();

    imdService.fetchMapData(selectedSources, maC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, mapData: res.result, loading: { ...oldState.loading, map: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, map: false } }));
        message.error('Failed to load map data!');
      });
  }, [selectedSources]);

  const loadTypeData = useCallback(() => {
    if (!state.loading.type) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, type: true } }));
    }

    if (tyC.current) {
      tyC.current.abort();
    }

    tyC.current = new AbortController();

    imdService.fetchTypeData(selectedSources, tyC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, typeData: res.result, loading: { ...oldState.loading, type: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, type: false } }));
        message.error('Failed to load type data!');
      });
  }, [selectedSources]);

  const loadAddressData = useCallback(() => {
    if (!state.loading.address) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, address: true } }));
    }

    if (adC.current) {
      adC.current.abort();
    }

    adC.current = new AbortController();

    imdService.fetchAddressData(selectedSources, adC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, addressData: res.result, loading: { ...oldState.loading, address: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, address: false } }));
        message.error('Failed to load address data!');
      });
  }, [selectedSources]);

  const loadTreeData = useCallback((institute: string) => {
    if (!state.loading.address) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, tree: true } }));
    }

    if (tdC.current) {
      tdC.current.abort();
    }

    tdC.current = new AbortController();

    imdService.fetchTreeData(selectedSources, institute, tdC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, treeData: res.tree, loading: { ...oldState.loading, tree: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, tree: false } }));
        message.error('Failed to load tree data!');
      });
  }, [selectedSources]);

  const searchTreeData = useCallback((query: string) => {
    if (!state.loading.address) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, treeSearch: true } }));
    }

    if (tdsC.current) {
      tdsC.current.abort();
    }

    tdsC.current = new AbortController();

    imdService.searchInstitutionsTree(selectedSources, query, tdsC.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, treeSearchData: res.result, loading: { ...oldState.loading, treeSearch: false } }));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, treeSearch: false } }));
        message.error('Failed to load tree data!');
      });
  }, [selectedSources]);

  const clearTreeData = () => {
    setState(oldState => ({ ...oldState, treeData: [], treeSearchData: [] }));
  };

  const loadSources = () => {
    if (!state.loading.sources) {
      setState(oldState => ({ ...oldState, loading: { ...oldState.loading, sources: true } }));
    }

    if (soC.current) {
      soC.current.abort();
    }

    soC.current = new AbortController();

    imdService.fetchSources(soC.current.signal)
      .then(res => {
        setState(oldState => ({
          ...oldState,
          sources: res.result.map(item => ({ label: `${item.name} (${item.count})`, value: item.id })),
          loading: { ...oldState.loading, sources: false }
        }));
        setSelectedSources(res.result.map(item => item.id));
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: { ...oldState.loading, sources: false } }));
        message.error('Failed to load data sources!');
      });
  };

  useEffect(() => { loadSources(); }, []);

  useLazyEffect(() => {
    setState(oldState => ({ ...oldState, ...emptyData, loading: { sources: false } }));
    if (selectedSources.length) {
      loadMapData();
      loadTypeData();
      loadAddressData();
    }
  }, [selectedSources]);

  return {
    selectedSources,
    setSelectedSources,
    loadTreeData,
    searchTreeData,
    clearTreeData,
    ...state
  };
};

export default useBrowse;
import React from 'react';
import classes from './samples-table.module.sass';
import { IMD } from '../../../@types/ime';
import { DATA_COLUMNS } from '../../../constants/sample-data-table';

import { Button, Card, Flex, Pagination, Spin, Table, Tooltip } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { ArrowClockwise } from '@phosphor-icons/react';
import RowDetails from './row-details.component';

interface IProps {
  data: IMD.ISampleDataEntry[];
  loading: boolean;
  onRefresh: () => void;
}

const SamplesTable = (props: IProps) => {
  const [params, setParams] = useSearchParams();

  const size = Number(params.get('size') || 5);

  return (
    <Spin
      wrapperClassName={classes.wrapper}
      tip="Loading Institutions Type Stats..."
      spinning={props.loading}
    >
      <Card
        style={{ width: '100%' }}
        bordered={false}
        hoverable
      >
        <Flex className={classes.title} justify="space-between" align="center">
          <h2>Data Samples</h2>
          <Flex>
            <Tooltip title="Load new random data">
              <Button
                shape="circle"
                onClick={() => props.onRefresh()}
                style={{ paddingTop: '6px', marginLeft: 15 }}
                icon={<ArrowClockwise size={18} />}
                disabled={false}
              />
            </Tooltip>
            <Pagination
              className={classes.paginator}
              total={size}
              pageSize={size}
              pageSizeOptions={[1, 5, 10, 20]}
              showSizeChanger={true}
              hideOnSinglePage={false}
              onShowSizeChange={(_, size) => {
                const p = new URLSearchParams({ size: size.toString() });
                setParams(p);
              }}
            />
          </Flex>
        </Flex>
        {
          props.data.length &&
          <Table
            rowKey={r => `${r.affiliationId}_${r.institutionId}`}
            dataSource={props.data}
            columns={DATA_COLUMNS}
            className={classes.table}
            pagination={false}
            expandable={{
              expandedRowRender: r => <RowDetails record={r} />,
              rowExpandable: (record) => Boolean(record.institutionAncestors.length),
              expandRowByClick: true              
            }}
          />
        }
      </Card>
    </Spin>
  );
};

export default SamplesTable;

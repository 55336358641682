import classes from './results.module.sass';
import { Flex } from 'antd';
import { Map } from '../../components/core';
import { AffiliationStatistics, SamplesTable } from '../../components/results';

import { useResults } from '../../hooks';
import { InstitutionsTypePie, ColumnChart } from '../../components/reports';

const ResultsPage = () => {
  const results = useResults();

  return (
    <div className={classes.wrapper}>
      <Flex vertical gap={25}>
        <Flex align="center" justify="flex-start" vertical gap={30}>
          <Flex style={{ width: '100%' }}>
            <AffiliationStatistics data={results.affiliationStats} loading={results.loading.affiliation} />
          </Flex>
          <Map
            data={results.mapData}
            loading={results.loading.map}
          />
          <Flex className={classes.adaptiveStats} gap={25} wrap="wrap" style={{ width: '100%' }}>
            <InstitutionsTypePie data={results.typeData} height={400} loading={results.loading.type} />
            <ColumnChart
              title="Matched Results Levels"
              data={results.levelData}
              height={400}
              loading={results.loading.level}
            />
            {
              results.sourcesData.length > 1 && false &&
              <ColumnChart
                title="Results Sources Distribution"
                data={results.sourcesData}
                height={400}
                loading={results.loading.sources}
              />
            }
          </Flex>
          <Flex style={{ width: '100%' }}>
            <SamplesTable
              data={results.sampleData}
              loading={results.loading.sample}
              onRefresh={results.loadSampleTableData}
            />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default ResultsPage;
import { ConfigProvider, App } from 'antd';
import theme from './constants/theme';
import './assets/vars.sass'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { InstitutionsPage, ResultsPage } from './pages';
import { PageWrapper } from './components/core';

function IDApp() {
  return (
    <ConfigProvider theme={theme}>
      <App>
        <BrowserRouter basename="/">
          <PageWrapper>
            <Routes>
              <Route path="/institutions" element={<InstitutionsPage />} />
              <Route path="/results/:collection" element={<ResultsPage />} />
              <Route path="*" element={<Navigate to="/institutions" replace />} />
            </Routes>
          </PageWrapper>
        </BrowserRouter>
      </App>
    </ConfigProvider>
  );
}

export default IDApp;
